import React, { useState, useEffect } from 'react'
import { Form } from "react-bootstrap"
import { Rating } from 'react-simple-star-rating'
import Col from "react-bootstrap/Col"

const RatingField = ({name, rows, resetform, placeholder, required, handlechange, fieldClass, label, grpmd}) => {

  const [rating, setRating] = useState(0)

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }

  useEffect(() => {
    if (resetform == true && rating != 0) {
      setRating(0)
    }
  },[resetform])

  return (
    <Form.Group className="form-field-group text-area" controlId="validation{name}">
      { label
        ? <Form.Label className='form-label'>{label}{required ? '*' : ''}</Form.Label>
        : ''
      }
      <div className='star-rating-field'>
        <input name={name} type="hidden" required  value={rating} />
        <Rating 
            onClick={handleRating}
            initialValue={rating}
            transition
            allowFraction={true}
            size={38}
            SVGstrokeColor='#D2CFCA'
            SVGstorkeWidth={1}
            emptyColor='transparent'
        />
      </div>
    </Form.Group>
  )
}

export default RatingField;