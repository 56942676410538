import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import SiteBreadcrumbs from '../components/Breadcrumb/site-breadcrumbs';
import FeedbackForm from "../components/forms/feedback-form"
import ContactCard from "../components/ContactCard/ContactCard"
import BookingCard from '../components/ContactCard/BookingCard';
import { GetPropertyBooking } from '../queries/common_use_query';

const FeedbackFormPage = ({pid}) => {
    const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
    var propertyurl= ""
    var backurl = ""
    var propertyslug = ""
    var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
    var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";
    if(prop_data && prop_data.properties.length > 0) {
        propertyurl = searchtype == "sales"?"/property-for-sale/":"/property-to-rent/"
        backurl = searchtype == "sales"?"/property/for-sale/in-london":"/property/to-rent/in-london"
        propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id+'/';
    }
    return (
        <>
            <Header />

            <SiteBreadcrumbs style="landing-breadcrumbs" menulabel="Feedback" />
            <Container className="contact-form-wrapper feedback-form">
                <Row>
                    <Col lg={7}>
                        <div className="contact-title">
                            <h1>Feedback Form</h1>
                            <p>We would like to hear your feedback about <span>{displayaddress}</span> from your recent viewing.</p>
                        </div>
                        <FeedbackForm />
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4} className="mt-lg-0 mt-5">
                        {prop_data && prop_data.properties.length > 0 ?
                            <BookingCard property={prop_data && prop_data.properties[0]} property_url={propertyurl+propertyslug}/>    
                        :
                            <ContactCard />
                        }
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default FeedbackFormPage